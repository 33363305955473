import {Form} from 'semantic-ui-react'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateCustomer} from "../../reducers/Create/create.actions";
import {useForm} from "react-hook-form";
import PageHeader from "../PageHeader/PageHeader";
import axiosInstance from "../../utils/axiosInstance";
import {useTranslation} from "react-i18next";

function CustomerFormComponent(props) {

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const createIntervention = useSelector(state => state.create);

    const {register, getValues, watch, setValue, formState: {errors, isValid, isDirty}} = useForm({
        mode: 'onBlur'
    });

    const {setStep, setPreviousButtonState, setNextButtonState} = props;
    const zipCode = watch("zipCode");

    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {
        setNextButtonState({
            disabled: !isValid,
            label: t('generic.next'),
            isPrimary: true,
            onNext: () => {
                dispatch(updateCustomer(getValues()));
                setStep("FINAL_VALID");
            }
        })
    }, [isDirty, isValid])

    useEffect(() => {
        document.getElementById('lastName').focus();
        setPreviousButtonState({
            disabled: false,
            onPrevious: () => {
                setStep("SELECT_REPAIRS")
            }
        });
    }, [])

    useEffect(() => {
        if (zipCode && zipCode.length === 5) {
            axiosInstance.get("/zipcode/"+zipCode)
                .then(response => {
                    if (response.data) {
                        const cityData = response.data;
                        if (Array.isArray(cityData)) {
                            setCityList(cityData);
                            setSelectedCity(cityData[0] || '');
                            setValue("city", cityData[0] || '');
                        }
                    }
                })
        }
    }, [zipCode, setValue]);

    const handleCityChange = (event) => {
        const city = event.target.value;
        setSelectedCity(city);
        setValue("city", city);
    };

    return (
        <>
            <PageHeader title={t('page.intervention.customer-infos')}/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field required={true}>
                        <label htmlFor="lastName">{t('generic.lastname')}</label>
                        <input fluid name="lastName" id={"lastName"}
                               defaultValue={createIntervention.customer?.lastName}
                               {...register('lastName', {required: t('formError.requiredLastName')})}
                               placeholder={t('generic.lastname')}/>
                        {errors.lastName && <p style={{color: 'red'}}>{errors.lastName.message}</p>}
                    </Form.Field>
                    <Form.Field required={true}>
                        <label htmlFor="firstName">{t('generic.firstname')}</label>
                        <input fluid name="firstName" id={"firstName"}
                               defaultValue={createIntervention.customer?.firstName}
                               {...register('firstName', {required: t('formError.requiredFirstName')})}
                               placeholder={t('generic.firstname')}/>
                        {errors.firstName && <p style={{color: 'red'}}>{errors.firstName.message}</p>}
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field required={false}>
                        <label htmlFor="address">{t('generic.address')}</label>
                        <input fluid name="address" defaultValue={createIntervention.customer?.address}
                               {...register('address', {required: false})}
                               placeholder={t('generic.address')}/>
                    </Form.Field>
                    <Form.Field required={true}>
                        <label htmlFor="zipCode">{t('generic.postal-code')}</label>
                        <input fluid name="zipCode" defaultValue={createIntervention.customer?.zipCode}
                               {...register('zipCode', {
                                   required: t('formError.requiredZipCode'),
                                   pattern: {
                                       value: /^[0-9]{4,10}$/,
                                       message: t('formError.invalidZipCode')
                                   }
                               })}
                               placeholder={t('generic.postal-code')}
                               onInput={(e) => {
                                   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                               }}
                        />
                        {errors.zipCode && <p style={{color: 'red'}}>{errors.zipCode.message}</p>}
                    </Form.Field>
                    <Form.Field required={false}>
                        <label htmlFor="city">{t('generic.city')}</label>
                        {cityList.length <= 1 ? (
                            <input
                                type="text"
                                name="city"
                                id="city"
                                onChange={handleCityChange}
                                {...register('city', {required: false})}
                                placeholder={t('generic.city')}
                            />
                        ) : (
                            <select
                                name="city"
                                id="city"
                                onChange={handleCityChange}
                                {...register('city', { required: false })}
                            >
                                {cityList.map((city, index) => (
                                    <option key={index} value={city}>
                                        {city}
                                    </option>
                                ))}
                            </select>
                        )}
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field required={true}>
                        <label htmlFor="email">{t('generic.email')}</label>
                        <input fluid name="email" id={"email"} defaultValue={createIntervention.customer?.email}
                               {...register('email', {
                                   required: t('formError.requiredEmail'),
                                   pattern: {
                                       value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                       message: t('formError.invalidEmail')
                                   }
                               })}
                               placeholder={t('generic.email')}/>
                        {errors.email && <p style={{color: 'red'}}>{errors.email.message}</p>}
                    </Form.Field>
                    <Form.Field required={false}>
                        <label htmlFor="phone">{t('generic.phone-number')}</label>
                        <input
                            name="phone"
                            defaultValue={createIntervention.customer?.phone}
                            {...register('phone', {
                                required: false,
                                pattern: {
                                    value: /^[0-9]+$/,
                                    message: t('formError.invalidPhoneNumber')
                                }
                            })}
                            placeholder={t('generic.phone-number')}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                        />
                        {errors.phone && <p style={{color: 'red'}}>{errors.phone.message}</p>}
                    </Form.Field>
                </Form.Group>
            </Form>
        </>
    );
}


export default CustomerFormComponent;
