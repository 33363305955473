import {Divider, Grid, Icon, Image, Label, Table} from 'semantic-ui-react';
import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {NumericFormat} from "react-number-format";

function NewResumeComponent(props) {

    const createIntervention = useSelector((state) => state.create);
    const {t} = useTranslation();

    let productImg = null;
    if (createIntervention.product === null) {
        // TODO : change img
        productImg = "";
    } else {
        productImg = createIntervention.product?.imgURL ? createIntervention.product.imgURL : "https://asset-fr.trepidai.pro/model/default.png";
    }

    let totalDiscountAmountTTC = 0;
    let totalDiscountedAmountTTC = 0;
    if (createIntervention.repairs && createIntervention.repairs.length > 0) {
        totalDiscountAmountTTC = createIntervention.repairs.map((repair) => (repair.discountAmountTTC)).reduce((x, y) => x + y, 0)
        totalDiscountedAmountTTC = createIntervention.repairs.map((repair) => (repair.discountedAmountTTC)).reduce((x, y) => x + y, 0)
    }

    const showPrice = createIntervention.guaranteeType != 'COV_BROKER' && createIntervention.guaranteeType != 'INSURANCE';

    return (
        <>

            <div className='resume-card'>
                <div className="resume-card-title">
                    {t('page.intervention.summary')}
                </div>
                <Grid divided centered style={{marginTop: '5px'}}>
                    <Grid.Row>
                        <Grid.Column width={4} textAlign={"center"} verticalAlign={"middle"}>

                            {productImg && <Image src={productImg} size={"small"}/>}
                            {!productImg && <Icon name='question' size='big'/>}

                            <div>{createIntervention.color && t('colors.' + createIntervention.color)}</div>
                        </Grid.Column>
                        <Grid.Column width={8} style={{minHeight: '60px'}}>
                            <div>{createIntervention.productType && t('product.type.' + createIntervention.productType)}</div>
                            <div>{createIntervention.product ? (createIntervention.product.brand?.name + " " + createIntervention.product?.model?.name) : null}</div>
                            <div>{createIntervention.product ? (createIntervention.product.imei ? createIntervention.product.imei : createIntervention.product.serial) : null}</div>
                        </Grid.Column>
                    </Grid.Row>

                    {createIntervention.guaranteeType && createIntervention.guaranteeType != 'UNDEFINED' &&
                        <Label basic={true} size={"small"}
                               color={"teal"}>{t('generic.repair')} {t('guarantee.' + createIntervention.guaranteeType)}</Label>}


                    {createIntervention.repairs && createIntervention.repairs.length > 0 &&
                        <>
                            <Table basic={"very"} size={"small"} compact={true}
                                   style={{margin: '10px', marginTop: '15px'}}>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.HeaderCell>{t('generic.repair')}</Table.HeaderCell>
                                        {showPrice && <Table.HeaderCell textAlign={"right"}>{t('page.pricing.price-ttc')}</Table.HeaderCell>}
                                    </Table.Row>
                                    {createIntervention.repairs?.map((repair, index) => {
                                        return (
                                            <Table.Row style={{padding: '0px !important'}} key={index}>
                                                <Table.Cell style={{margin: '0px !important'}}>
                                                    {t('category.' + repair.category)}
                                                </Table.Cell>
                                                <Table.Cell textAlign={"right"}>
                                                    <NumericFormat
                                                        value={repair.priceTTC}
                                                        displayType={'text'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        decimalSeparator=','
                                                        suffix=' €'
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                    }

                                    {showPrice &&
                                        <>
                                            <Table.Row style={{padding: '0px !important'}}>
                                                <Table.Cell style={{margin: '0px !important'}}>
                                                    <Label size={"tiny"} basic color={"teal"}> {t('page.summary.partner-discount')}</Label>
                                                </Table.Cell>
                                                <Table.Cell textAlign={"right"}>
                                                    <NumericFormat
                                                        value={totalDiscountAmountTTC}
                                                        displayType={'text'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        decimalSeparator=','
                                                        suffix=' €'
                                                        prefix=' -'
                                                        allowNegative={false}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row style={{padding: '0px !important'}}>
                                                <Table.Cell style={{margin: '0px !important'}}>
                                                    <b>{t('page.summary.total')}</b>
                                                </Table.Cell>
                                                <Table.Cell textAlign={"right"}>
                                                    <b><NumericFormat
                                                        value={totalDiscountedAmountTTC}
                                                        displayType={'text'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        decimalSeparator=','
                                                        suffix=' €'
                                                    /></b>
                                                </Table.Cell>
                                            </Table.Row>
                                        </>
                                    }
                                </Table.Body>
                            </Table>
                        </>
                    }
                </Grid>

                {createIntervention.customer && <>
                    <Divider horizontal><Icon size={"tiny"} name={"circle outline"} color={"teal"}/> </Divider>
                    <p style={{marginLeft: '10px'}}>
                        {createIntervention.customer.firstName &&
                            <><Icon name={"user outline"}
                                    color={"teal"}></Icon> {createIntervention.customer.firstName + " " + createIntervention.customer.lastName}<br/></>
                        }
                        {createIntervention.customer.phone &&
                            <><Icon name={"phone"} color={"teal"}></Icon> {createIntervention.customer.phone}<br/></>
                        }
                        {createIntervention.customer.email &&
                            <><Icon name={"mail"} color={"teal"}></Icon> {createIntervention.customer.email}<br/></>
                        }
                        {createIntervention.customer.address &&
                            <><Icon name={"map marker alternate"}
                                    color={"teal"}></Icon> {createIntervention.customer.address}<br/></>
                        }
                        {createIntervention.customer.zipCode &&
                            <><Icon name={""}
                                    color={"teal"}></Icon> {createIntervention.customer.zipCode + " " + createIntervention.customer.city}<br/></>
                        }
                    </p>
                </>
                }
            </div>

        </>
    );
}

export default NewResumeComponent;
