import {
  Card,
  Form,
  Grid,
  GridColumn,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  TextArea,
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCreationWorkflow,
  updateGuaranteeType,
  updateRepairs,
} from "../../reducers/Create/create.actions";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import axiosInstance from "../../utils/axiosInstance";
import PageHeader from "../PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";
import SemanticDatepicker from "react-semantic-ui-datepickers";

function SelectRepairsComponent(props) {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const createIntervention = useSelector((state) => state.create);
  const [selectedRepairs, setSelectedRepairs] = useState([]);
  const [repairs, setRepairs] = useState([]);
  const { setStep, setPreviousButtonState, setNextButtonState } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [underGuaranteeComment, setUnderGuaranteeComment] = React.useState(
    createIntervention.guaranteeComment
      ? createIntervention.guaranteeComment
      : ""
  );

  const fetchPricings = () => {
    setIsLoading(true);
    axiosInstance
      .post(
        "/pricing",
        {
          brandName: createIntervention.product.brand.name,
          modelName: createIntervention.product.model.name,
          modelId: createIntervention.product.model.id,
        },
        {}
      )
      .then((response) => {
        const uniqueCategories = new Map();
        response.data.content.forEach((pricing) => {
          if (!uniqueCategories.has(pricing.category)) {
            pricing.label = t("category." + pricing.category);
            uniqueCategories.set(pricing.category, pricing);
          }
        });

        const categoriesArray = Array.from(uniqueCategories.entries());
        categoriesArray.sort((a, b) => {
          return a[1].label.localeCompare(b[1].label);
        });
        setRepairs(categoriesArray.map(([, value]) => value));

        setIsLoading(false);
        if (uniqueCategories.size === 0) {
          setNextButtonState({
            disabled: false,
            label: t("generic.quit"),
            isPrimary: true,
            onNext: () => {
              dispatch(clearCreationWorkflow());
              navigate("/");
            },
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSelectedRepairs(createIntervention.repairs);
    setPreviousButtonState({
      disabled: false,
      onPrevious: () => {
        setStep(
          user.broker.sgCoverBroker || user.broker.contractType === "INSURANCE"
            ? "SELECT_GUARANTEE"
            : "CHECK_DAMAGE"
        );
      },
    });
    if (createIntervention.guaranteeType === "NGU") {
      fetchPricings();
    } else {
      setRepairs([
        { category: "LCD" },
        { category: "BAT" },
        { category: "CAM_FRONT" },
        { category: "CAM_REAR" },
        { category: "MIC" },
        { category: "HP" },
        { category: "PCONNECTOR" },
        { category: "OTH" },
      ]);
    }
  }, []);

  useEffect(() => {
    setNextButtonState({
      disabled: selectedRepairs.length === 0,
      label: t("generic.next"),
      isPrimary: true,
      onNext: () => validStep(),
    });
  }, [selectedRepairs, underGuaranteeComment]);

  function validStep() {
    dispatch(updateRepairs(selectedRepairs));
    if (
      user.broker.hgBroker &&
      createIntervention.guaranteeType !== "COV_BROKER"
    ) {
      dispatch(updateGuaranteeType("NGU", underGuaranteeComment));
    }
    setStep("CUSTOMER_FORM");
  }

  function handleRepairClick(repair) {
    let newRepairsList = [];
    if (isRepairCodeSelected(repair.category)) {
      newRepairsList = selectedRepairs.filter(
        (value) => value.category !== repair.category
      );
    } else {
      newRepairsList = [...selectedRepairs, repair];
    }
    setSelectedRepairs(newRepairsList);
    dispatch(updateRepairs(newRepairsList));
  }

  function isRepairCodeSelected(category) {
    return (
      selectedRepairs.filter((repair) => repair.category === category).length >
      0
    );
  }

  return (
    <>
      <PageHeader title={t("page.repairs.subtitle")} />
      <Card.Group
        itemsPerRow={6}
        centered={true}
        textAlign={"center"}
        stackable={true}
        key={"abcd"}
      >
        {isLoading && (
          <>
            <div style={{ minHeight: "500px" }}>
              <Loader active size="large">
                {t("page.repairs.load-repairs")}
              </Loader>
            </div>
          </>
        )}

        {!isLoading && repairs.length === 0 ? (
          <Message negative>
            {t("page.repairs.no-pricing")}{" "}
            <b>
              <a href={"https://save.co/devis/"} target={"_blank"}>
                https://save.co/devis
              </a>
            </b>
            .
          </Message>
        ) : null}

        {!isLoading &&
          repairs.map((repair) => (
            <>
              {i18n.exists("category." + repair.category) && (
                <Card
                  onClick={() => handleRepairClick(repair)}
                  raised={true}
                  style={{ width: "130px" }}
                  key={repair.category}
                  color={isRepairCodeSelected(repair.category) ? "teal" : null}
                  centered={true}
                >
                  {isRepairCodeSelected(repair.category) && (
                    <Label corner color={"teal"}>
                      <Icon name="check" />
                    </Label>
                  )}

                  <Image
                    src={
                      "https://asset-fr.trepidai.pro/save/quote/quote-repair/partner/Icon_" +
                      repair.category +
                      ".svg"
                    }
                    style={{
                      width: "80px",
                      height: "80px",
                      margin: "auto",
                      background: "white",
                      padding: "10px",
                    }}
                  ></Image>
                  <div className="repair-card-text">
                    {t("category." + repair.category)}
                  </div>
                  {repair.priceTTC && (
                    <Label
                      attached="bottom"
                      style={{
                        textAlign: "center",
                        margin: "0px",
                        padding: "3px",
                      }}
                    >
                      <Grid
                        columns={2}
                        style={{ margin: "0px", padding: "0px" }}
                        verticalAlign={"middle"}
                      >
                        <GridColumn style={{ margin: "0px", padding: "0px" }}>
                          <Label size={"mini"} color={"teal"}>
                            <NumericFormat
                              value={repair.discountPercentage}
                              displayType={"text"}
                              fixedDecimalScale={false}
                              decimalScale={2}
                              decimalSeparator=","
                              suffix=" %"
                              prefix="- "
                              allowNegative={false}
                            />
                          </Label>
                        </GridColumn>
                        <GridColumn
                          stretched
                          style={{ margin: "0px", padding: "0px" }}
                          textAlign={"left"}
                        >
                          <NumericFormat
                            style={{ fontWeight: "bolder", color: "black" }}
                            value={
                              repair.discountAmountTTC
                                ? repair.discountedAmountTTC
                                : repair.priceTTC
                            }
                            displayType={"text"}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            decimalSeparator=","
                            suffix=" €"
                          />
                          {repair.discountAmountTTC && (
                            <>
                              <strike style={{ fontSize: "10px" }}>
                                <NumericFormat
                                  value={repair.priceTTC}
                                  displayType={"text"}
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  decimalSeparator=","
                                  suffix=" €"
                                />
                              </strike>
                            </>
                          )}
                        </GridColumn>
                      </Grid>
                    </Label>
                  )}
                </Card>
              )}
            </>
          ))}
      </Card.Group>

      {!isLoading &&
      user.broker.hgBroker &&
      createIntervention.guaranteeType !== "COV_BROKER" &&
      repairs.length !== 0 ? (
        <div style={{ marginTop: "25px" }}>
          <Form>
            <Form.Field
              control={TextArea}
              label={t("page.warranty.label-6")}
              placeholder={t("page.warranty.label-7")}
              value={underGuaranteeComment}
              onChange={(e) => setUnderGuaranteeComment(e.target.value)}
            />
          </Form>
        </div>
      ) : null}
    </>
  );
}

export default SelectRepairsComponent;
